html{
    height: 100%;
    width: 100%;
    scroll-behavior: smooth;
}

body {
    margin: 0;
    padding: 0;
    background-attachment: fixed;

}


@media only screen and (max-width: 1200px) {
    body{
        background-attachment: initial;
    }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
