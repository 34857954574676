.footer{
    width: 100%;
    text-align: center;
    padding: 0.5% 0;
    color: rgba(255, 255, 255, 0.432);
    font-family: 'Josefin Slab';
    background: linear-gradient(
        45deg,
        #c33764 0%,
        #7b4397 50%,
        #c33764 100%
    );
    background-size: 400% 400%;
	animation: gradient 15s ease infinite;
    fill-opacity: 0.5;
    filter: drop-shadow(0px -7px 4px #8b44dd);
}


@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}
